<template>
  <div class="map-grid">
    <header class="super-head">
      <div class="nav">
      </div>
      <div class="ctrl">
        <span class="switch">Available Markers: {{markers.length}}</span><span class="switch">acknowledged messages:<input type="checkbox" v-model="toggle" true-value=2 false-value=0 > </span>
      </div>
    </header>
  <div class="map-wrap">
  <div class="main">
      <gmap-map :center="center" :zoom="12" style="width: 100%; height: 500px">
        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
        </gmap-info-window>
        <gmap-marker :key="i" v-for="(m,i) in markers" :position="m.position" :clickable="true" :draggable="true" @click="toggleInfoWindow(m,i)" :icon="icon"></gmap-marker>
        <gmap-polyline ref="polyline" :path="paths" :options="currentOptions"></gmap-polyline>
      </gmap-map>
    <div id="legend">
      <div class="sliderLabel"><h1>Filter (days) back:</h1></div>
      <div class="slider"><vue-slider ref="slider" v-model="value" v-bind="options"></vue-slider></div>
      <div class="pathLabel"><h1>Path:</h1></div>
      <div class="pathCheckbox"><input type="checkbox" v-model="pathToggle" true-value=1 false-value=0 ></div>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
import store from '../../../store/store'
import vueSlider from 'vue-slider-component'

export default {
  name: 'map-display',
  components: {
    vueSlider
  },
  data() {
    return {
      currentOptions: {
        strokeColor: '#ffffff00'
      },
      center: {
        lat: 10.0,
        lng: 10.0
      },
      value: 1,
      options: {
        data: null,
        eventType: 'auto',
        width: 'auto',
        height: 6,
        dotSize: 16,
        dotHeight: null,
        dotWidth: null,
        min: 1,
        max: 30,
        interval: 1,
        show: true,
        speed: 0.5,
        disabled: false,
        piecewise: false,
        useKeyboard: false,
        enableCross: true,
        piecewiseStyle: false,
        piecewiseLabel: false,
        tooltip: 'always',
        tooltipDir: 'top',
        reverse: false,
        data: null,
        clickable: true,
        realTime: false,
        lazy: false,
        formatter: null,
        bgStyle: null,
        sliderStyle: null,
        processStyle: null,
        piecewiseActiveStyle: null,
        piecewiseStyle: null,
        tooltipStyle: null,
        labelStyle: null,
        labelActiveStyle: null
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      markers:[],
      icon: "https://cdn-icons-png.flaticon.com/32/1688/1688472.png",
      toggle: 0,
      pathToggle:0,
      paths:[]
    }
  },
  watch : {
    value: function (val) {
      this.$store.dispatch('fetchMessages', {
        status: this.toggle,
        deviceId: this.$store.getters.selectedDevice.deviceId,
      });
    },
    userMessages: function (val) {
      var rawMarkets = [];

      this.paths = [];
      if(store.getters.userMessages) {
        for (var i = 0, l = store.getters.userMessages.length; i < l; i++) {
          var obj = store.getters.userMessages[i];
          if (obj.userApplicationId === 65535) {
            try {
              var messageData = JSON.parse(atob(obj.data));
              var telemetry = "<h4>Telemetry</h4>" +
                  "Latitude:" + messageData.lt + "<br>" +
                  "Longitude:" + messageData.ln + "<br>" +
                  "Last RSSI value (dBm):" + messageData.r + "<br>" +
                  "Course (degrees):" + messageData.c + "<br>" +
                  "Speed (km/h):" + messageData.s + "<br>" +
                  "Altitude (m):" + messageData.a + "<br>" +
                  "Tile current (A):" + messageData.ti + "<br>" +
                  "Battery current (A):" + messageData.bi + "<br>" +
                  "Battery voltage (V):" + messageData.bv + "<br>" +
                  "Solar current (A):" + messageData.si + "<br>" +
                  "Solar voltage (V):" + messageData.sv + "<br>" +
                  "Date:" + new Date(messageData.d * 1000).toGMTString() + "<br>"
              var rawPosition = '{"lat":' + messageData.lt + ',"lng":' + messageData.ln + '}';
              var rawMarker = '{"position":' + rawPosition + ',"infoText": \"' + telemetry + '\"}';

              const marker = {};
              var set1 = JSON.parse(rawMarker);
              var cleanPath = JSON.parse(rawPosition);

              Object.keys(set1)
                  .forEach(key => marker[key] = set1[key]);

              Object.keys(messageData)
                  .forEach(key => marker[key] = messageData[key]);

              if (i == (store.getters.userMessages.length - 1)) {
                this.$store.dispatch('mapSelectedLocation', marker);
              }
              var messageDate = new Date(messageData.d * 1000);
              const millis = Date.now() - messageDate.getTime()
              if(millis < (this.value * 86400 * 1000)) {
                rawMarkets.push(marker);
                this.paths.push(cleanPath);
              }

            } catch
                (error) {
              console.log(obj.data.toString());
              console.log(error);
              this.$store.dispatch('mapSelectedLocation', null);

            }
          }
          if (rawMarkets.length > 0) {
            this.center.lat = rawMarkets[0].position.lat;
            this.center.lng = rawMarkets[0].position.lng;
          }
        }
        this.markers = rawMarkets;
      }
    },
    toggle: function (val) {
      if(!val) {
        this.paths = [];
      } else {
        this.$store.dispatch('mapSelectedLocation', null);
        this.$store.dispatch('fetchMessages', {
          status: this.toggle,
          deviceId: this.$store.getters.selectedDevice.deviceId,
        });
      }
    },
    pathToggle: function (val) {
      if(val == 1) {
        this.currentOptions.strokeColor = '#000000'
      }

      if(val == 0) {
        console.log('reload false')
        window.location.reload();
      }
    }
  },
  mounted() {
    this.$store.dispatch('mapSelectedLocation', null);

    if(this.$store.getters.selectedDevice) {
      this.$store.dispatch('fetchMessages', {
        deviceId: this.$store.getters.selectedDevice.deviceId,
        status: this.toggle
      });
    }
  },
  methods: {
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    }
  },
  computed: {
    userMessages() {
      var messages = this.$store.getters.userMessages;
      const sortedActivities = messages.sort((a, b) => this.$moment(b.hiveRxTime).format("MMM Do, YYYY HH:mm").date - this.$moment(a.hiveRxTime).format("MMM Do, YYYY HH:mm").date)
      return sortedActivities;
    }
  }
}
</script>
<style lang="scss" scoped>
.map-wrap {
  display: flex;
  .main {
    flex:1;
  }
  .sidebar {
    flex: 332px 0 0;
    margin-left: 32px;
    @media(max-width: 1000px) {
      margin-left: 0px !important;
    }
  }
}

.map-grid {
  background-color: #FFFFFF;
  border-radius: 2px;
  border: 1px solid rgba(17, 18, 21, 0.1);

  .super-head {
    height: 55px;
    display: flex;
    justify-content: space-between;

    .nav {
      display: flex;
      padding-left: 18px;

    }

    .ctrl {
      display: flex;
      align-items: center;
      padding-right: 24px;

    }
  }
  .switch {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 14px;
    margin-left: 40px;
  }
}

#legend {
  width: 100%;
  height: 64px;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  align-items: end;
}
.sliderLabel {
  display: inline-block;
  width: 20%;
  line-height: 24px;
  h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.17px;
    line-height: 28px;
    margin-bottom:0;
  }
}
.slider {
  display: inline-block;
  width: 72%;
}
.pathLabel {
  display: inline-block;
  width: 6%;
  line-height: 24px;
  h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.17px;
    line-height: 28px;
    margin-bottom:0;
  }
}
.pathCheckbox {
  display: inline-block;
  width: 2%;
}

</style>
